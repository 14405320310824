import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { nl2br } from '@utils/help';
import { colors } from '@utils/ui_palette';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Components
import HomeTitle from '@components/home/component/HomeTitle';

interface ITechnology {
    title: string;
    description: string[];
    image: string;
}

function Technology() {
    const technologyData:ITechnology[] = [
        {
            title: '<strong>업계최고 기술력으로</strong>\n시장의 혁신과 신뢰를 선도',
            description: [
                '금융권 수준 보안/인프라/시스템/플랫폼 개발 및 서비스 운영',
                '자체 개발한 플랫폼 기반으로 안정적 서비스 운영',
                '분야별 최고 IT 전문 인력 구성',
            ],
            image: 'podoland_page_image_1.png',
        },
        {
            title: '<strong>적극적인 R&D 투자</strong>\n핵심 전문 인력 육성, 기술 개발',
            description: [
                '매년 기술 중심의 R&D(연구개발) 투자',
                '기술 인력 비중이 45~55%의 기술 중심 기업',
                '핵심 인재 육성 및 우수 인재 영입으로 다양한 기술 개발',
            ],
            image: 'podoland_page_image_2.png',
        },
        {
            title: '<strong>Organization\n& Manpower</strong>',
            description: [
                '글로벌 네트워크를 보유한 핵심 인력을 중심, 개발 및 서비스 운영',
                '데이터와 브랜딩 전략을 기반으로 다양한 구축 경험',
                '각 분야 최고의 전문성과 협업 시스템으로 사업 운영 및 추진',
            ],
            image: 'podoland_page_image_3.png',
        },
    ];

    useEffect(() => {
        AOS.init();
    });

    return (
        <Container>
            <AnimationBox
                data-aos="fade-up"
                data-aos-duration="3000"
            >
                <HomeTitle title="Our Strengths" />

                <SectionTitle>
                    <span>앞선 기술력</span>
                    으로
                    {' '}
                    <br />
                    글로벌 플랫폼 생태계를 구축합니다.
                </SectionTitle>
            </AnimationBox>

            <CardWrap
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-delay="1000"
            >
                {technologyData.map((item, index) => (
                    <CardBox key={`technology-${index}`}>
                        <CardImage src={`/img/home/${item.image}`} />
                        <CardBody>
                            <CardTitle dangerouslySetInnerHTML={{ __html: nl2br(item.title) }} />
                            <CardList>
                                {item.description.map((listItem, listIndex) => (
                                    <CardListItem
                                        key={`list-${index}-${listIndex}`}
                                        dangerouslySetInnerHTML={{ __html: nl2br(listItem) }}
                                    />
                                ))}
                            </CardList>
                        </CardBody>
                    </CardBox>
                ))}
            </CardWrap>
        </Container>

    );
}

const Container = styled.div`
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
    padding-bottom: 120px;
`;

const AnimationBox = styled.div``;

const SectionTitle = styled.div`
    margin-top: 27px;
    font-size: 22px;
    color: ${colors.Black200};
    line-height: 1.5;

    span {
        color: ${colors.Blue100};
        font-weight: 700;
    }
`;

const CardWrap = styled.div`
    margin-top: 25px;
`;

const CardBox = styled.div`
    width: 100%;
    margin-bottom: 25px;
`;

const CardImage = styled('img')`
    width: 100%;
    border-radius: 20px 20px 0 0;
`;

const CardBody = styled.div`
    background-color: ${colors.BlueGray200};
    border-radius: 0 0 20px 20px;
    padding: 25px;
`;

const CardTitle = styled.div`
    font-size: 20px;
    color: ${colors.Black100};
    line-height: 1.4;
`;

const CardList = styled.div`
    margin-top: 14px;
`;

const CardListItem = styled.div`
    font-size: 16px;
    line-height: 1.4;
    color: ${colors.BlueGray800};
    margin-bottom: 11px;
    word-break: keep-all;
    padding-left: 16px;
    position: relative;
    z-index: 2;

    &:before {
        width: 4px;
        height: 4px;
        background-color: ${colors.BlueGray800};
        content: '';
        display: block;
        margin-right: 10px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 14px;
        z-index: 2;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export default Technology;
