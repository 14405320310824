import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { nl2br } from '@utils/help';
import { colors } from '@utils/ui_palette';

import AOS from 'aos';
import 'aos/dist/aos.css';

interface IBusiness {
    type: string;
    title: string;
    description: string | null;
    bg: string;
}

function Business() {
    const businessData:IBusiness[] = [
        {
            type: 'education',
            title: 'Education｜교육',
            description: '즐거움을 발견하고\n새로움을 추구하는 교육 서비스',
            bg: '#F0FCFE',
        },
        {
            type: 'creative',
            title: 'Creative｜창작',
            description: '사회적 편견을 넘은\n크리에이티브 콘텐츠 서비스',
            bg: '#FEF7F4',
        },
        {
            type: 'health',
            title: 'Health｜건강',
            description: '가치 있는 경험\n건강한 라이프 스타일 서비스',
            bg: '#F9F1FE',
        },
        {
            type: 'environment',
            title: 'Environment｜환경',
            description: '지속 가능한 일상을 위한\n디지털 환경 서비스',
            bg: '#EFFBF7',
        },
    ];

    useEffect(() => {
        AOS.init();
    });

    return (
        <Container>
            <BusinessItems
                type="title"
                bg="transparent"
                data-aos="fade-up"
                data-aos-duration="3000"
            >
                <Title
                    type="title"
                >
                    더 많은 사람들이
                    <br />
                    더 나은 내일을 경험할 수 있도록
                    <br />
                    <strong>미래의 IT산업</strong>
                    을 선도합니다.
                </Title>
            </BusinessItems>
            <BusinessBox>
                {businessData.map((item, index) => (
                    <BusinessItems
                        key={`business-${item.type}-${index}`}
                        type={item.type}
                        bg={item.bg}
                        data-aos="fade-up"
                        data-aos-duration="3000"
                        data-aos-delay={item.type === 'title' ? '' : '1000'}
                    >
                        {item.type !== 'title' && (
                            <ImageBox src={`/img/home/img_${item.type}.png`} />
                        )}

                        <Title
                            type={item.type}
                            dangerouslySetInnerHTML={{ __html: nl2br(item.title) }}
                        />

                        {item.description && (
                            <Description dangerouslySetInnerHTML={{ __html: nl2br(item.description) }} />
                        )}
                    </BusinessItems>
                ))}
            </BusinessBox>
        </Container>

    );
}

const Container = styled.div`
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
    padding-top: 58px;
    padding-bottom: 121px;
`;

const BusinessBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const BusinessItems = styled.div<{type: string, bg: string}>`
    flex-basis: calc(50% - 5px);  
    margin-bottom: ${(props) => (props.type === 'title' ? '26px' : '20px')};
    border-radius: 20px;
    text-align: center;
    background-color: ${(props) => (props.bg)};
    height: ${(props) => (props.type === 'title' ? 'inherit' : '235px')};
    padding: ${(props) => (props.type === 'title' ? 0 : '8px 17px')};
    padding-bottom: ${(props) => (props.type === 'title' ? '28px' : 0)};
    line-height: ${(props) => (props.type === 'title' ? '1.5' : '1.3')};
`;

const Title = styled.div<{type: string}>`
    font-size: ${(props) => (props.type === 'title' ? '22px' : '16px')};
    font-weight: ${(props) => (props.type === 'title' ? '400' : '700')};
    color: ${(props) => (props.type === 'title' ? colors.Black200 : colors.Black100)};

    strong {
        color: #1793FF;
        font-weight: 700;
    }
`;

const Description = styled.div`
    font-size: 13px;
    color: ${colors.BlueGray800};
    margin-top: 12px;
    word-break: keep-all;
`;

const ImageBox = styled('img')`
    width: 120px;
`;

export default Business;
