import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@utils/ui_palette';

import { TypeAnimation } from 'react-type-animation';
import AOS from 'aos';
import 'aos/dist/aos.css';

function MainVisual() {
    const [different, setDeifferent] = useState<boolean>(false);
    const [notLess, setNotLess] = useState<boolean>(false);

    useEffect(() => {
        AOS.init();

        setTimeout(() => {
            setDeifferent(true);
        }, 1000);

        setTimeout(() => {
            setNotLess(true);
        }, 2000);
    });

    return (
        <SectionDummy>
            <MainVisualBox
                data-aos="fade-up"
                data-aos-duration="3000"
            >
                <Container>
                    <Title>
                        {different && (
                            <TypeAnimation
                                sequence={[
                                    'Different,',
                                ]}
                                wrapper="span"
                                cursor={false}
                                speed={20}
                                style={{
                                    fontSize: '46px', fontWeight: '600', fontFamily: 'Poppins', color: colors.Brown100,
                                }}
                            />
                        )}
                        <br />
                        {notLess && (
                            <TypeAnimation
                                sequence={[
                                    'Not Less',
                                ]}
                                wrapper="span"
                                cursor={false}
                                speed={20}
                                style={{
                                    fontSize: '46px', fontWeight: '600', fontFamily: 'Poppins', color: colors.Brown100,
                                }}
                            />
                        )}

                    </Title>
                    <SubTitle>
                        포도랜드는 차이를 존중하며
                        <br />
                        평등하고 경계 없는
                        <br />
                        플랫폼 세상을 만들어 나갑니다.
                    </SubTitle>
                </Container>
            </MainVisualBox>
        </SectionDummy>
    );
}

const SectionDummy = styled.div`
    width: 100%;
    height: 740px;
`;

const MainVisualBox = styled.div`
    width: 100%;
    height: 740px;
    background-color: #F0ECE7;
    background-image: url('/img/home/img_main_visual.png');
    padding-top: 118px;
    background-size: contain;
    background-repeat: no-repeat; 
    background-position: center center;
`;

const Container = styled.div`
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
`;

const Title = styled.div`
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: ${colors.Brown100};
    font-weight: 600;
    font-size: 46px;
    min-height: 129px;
    line-height: 1.4;
`;

const SubTitle = styled.div`
    text-align: center;
    margin-top: 10px;
    color: ${colors.BlueGray800};
    font-size: 18px;
    line-height: 1.5;
`;

export default MainVisual;
