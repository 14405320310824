import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Components
import HomeTitle from '@components/home/component/HomeTitle';
import RoadPoint from '@components/home/component/RoadPoint';
import { colors } from '@utils/ui_palette';

function RoadMap() {
    useEffect(() => {
        AOS.init();
    });

    return (
        <Container>
            <AnimationBox
                data-aos="fade-up"
                data-aos-duration="3000"
            >
                <HomeTitle title="Roodmap" />
            </AnimationBox>

            <SetFirst>
                <RoadMapBar
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    width={100}
                    padding
                    arrow={false}
                >
                    <RoadSection>
                        <RoadPoint
                            color="#1793FF"
                            title="포도아이"
                            en_title="PODO I"
                            years={2023}
                            isMargin
                            isYear
                        />
                    </RoadSection>

                    <RoadSection>
                        <RoadPoint
                            color="#1793FF"
                            title="포도시드"
                            en_title="PODO SEED"
                            years={2023}
                            isMargin
                            isYear={false}
                        />
                    </RoadSection>
                </RoadMapBar>
            </SetFirst>
            <RoadMapBar
                data-aos="fade-up"
                data-aos-duration="3000"
                width={100}
                padding={false}
                arrow={false}
            >
                <RoadSection>
                    <RoadPoint
                        color="#002BFF"
                        title="포도케어"
                        en_title="PODO CARE"
                        years={2024}
                        isMargin
                        isYear
                    />
                </RoadSection>

                <RoadSection>
                    <RoadPoint
                        color="#002BFF"
                        title="포도리뉴"
                        en_title="PODO RENEW"
                        years={2023}
                        isMargin
                        isYear={false}
                    />
                </RoadSection>
            </RoadMapBar>
            <RoadMapBar
                data-aos="fade-up"
                data-aos-duration="3000"
                width={50}
                padding={false}
                arrow
            >
                <RoadSection>
                    <RoadPoint
                        color="#5D00FF"
                        title="플랫폼&글로벌"
                        en_title="Platfrom&global"
                        years={2025}
                        isMargin
                        isYear
                    />
                </RoadSection>
            </RoadMapBar>
        </Container>

    );
}

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-bottom: 80px;
`;

const AnimationBox = styled.div`
    padding: 0 16px;
    padding-top: 81px;
`;

const SetFirst = styled.div`
    padding-left: 16px;
`;

const RoadMapBar = styled.div<{width: number, padding: boolean, arrow: boolean}>`
    width: ${(props) => (`calc(${props.width}%)`)};
    height: 6px;
    margin-top: ${(props) => (props.padding ? '70px' : '140px')};
    padding-left: ${(props) => (props.padding ? 0 : '16px')};
    background-color: ${colors.BlueGray500};
    position: relative;
    display: flex;

    &:before {
        width: 17px;
        height: 25px;
        background-image: url('/img/home/ic_roadmap_arrow.svg');
        background-repeat: no-repeat;
        background-size: contain;
        display: ${(props) => (props.arrow ? 'block' : 'none')};;
        content: '';
        position: absolute;
        top: 50%;
        right: -17px;
        transform: translate(0, -50%);
    }
`;

const RoadSection = styled.div`
    padding-left: 34px;
    flex-basis: 45%;
`;

export default RoadMap;
