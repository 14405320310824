import React from 'react';
import styled from '@emotion/styled/macro';
import moment from 'moment';
import { colors } from '@utils/ui_palette';

function Footer() {
    return (
        <CopyRightsSection>
            <Container>
                <Logo src="/img/layout/img_footer_logo.png" />

                <CompanyInfoBox>
                    <InformationName>
                        포도랜드(PODO LAND)
                    </InformationName>
                    <Information>
                        T. 070-8855-8782
                    </Information>
                    <Information>
                        F. 02-792-8580
                    </Information>
                    <Information>
                        E. contact@podoland.org
                    </Information>
                    <Information>
                        서울시 용산구 독서당로 124-1번지 , 지하 1층 포도랜드 (우 04420)
                    </Information>
                </CompanyInfoBox>

                <CopyRight>
                    Copyrightⓒ
                    {moment().format('YYYY')}
                    PODOLAND. All Rights Reserved.
                </CopyRight>
            </Container>
        </CopyRightsSection>
    );
}

const CopyRightsSection = styled.div`
    background-color: ${colors.Black200};    
    padding-top: 60px;
    padding-bottom: 127px;
`;

const Container = styled.div`
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
`;

const Logo = styled('img')`
    width: 129px;
`;

const CompanyInfoBox = styled.div`
    margin-top: 33px;
`;

const InformationName = styled.div`
    font-size: 14px;
    color: ${colors.White100};
    margin-bottom: 14px;
`;

const Information = styled.div`
    font-size: 12px;
    color: ${colors.White100};
    margin-bottom: 6px;
`;

const CopyRight = styled.div`
    font-size: 12px;
    color: ${colors.BlueGray800};
    margin-top: 32px;
`;

export default Footer;
