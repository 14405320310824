import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Compoents
import MainVisual from '@components/home/MainVisual';
import Business from '@components/home/Business';
import Technology from '@components/home/Technology';
import Services from '@components/home/Services';
import RoadMap from '@components/home/RoadMap';
// import CompanyNews from '@components/home/CompanyNews';
import Partners from '@components/home/Partners';

function Home() {
    useEffect(() => {
        AOS.init();
    });

    return (
        <Container>
            {/* 메인 비쥬얼 */}
            <MainVisual />

            {/* 비즈니스 섹션 */}
            <Business />

            {/* 기술 관련 섹션 */}
            <Technology />

            {/* 서비스 섹션 */}
            <AnimationBox
                data-aos="fade-up"
                data-aos-duration="3000"
            >
                <Services />
            </AnimationBox>

            {/* 로드 맵 */}
            <RoadMap />

            {/* 뉴스 섹션 */}
            {/* <CompanyNews /> */}

            {/* 파트너 섹션 */}
            <Partners />
        </Container>
    );
}

const Container = styled.div`
    margin: 0 auto;
`;

const AnimationBox = styled.div``;

export default Home;
