import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import Slider from 'react-slick';
import { nl2br } from '@utils/help';
import { colors } from '@utils/ui_palette';

// Components
import HomeTitle from '@components/home/component/HomeTitle';

interface IService {
    title: string;
    en_title: string;
    description: string;
    type: string;
    index: number;
}

function Services() {
    const [slideInfo, setSlideInfo] = useState<IService>({
        title: '포도아이',
        en_title: 'PODO I',
        description: '재미있고 효과적인\n체계적인 1:1 맞춤 교육 플랫폼',
        type: 'i',
        index: 3,
    });

    const settings = {
        dots: true,
        autoplay: true,
        centerMode: true,
        fade: false,
        swipe: true,
        swipeToSlide: true,
        centerPadding: '0',
        speed: 100,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        beforeChange: (current: number, next: number) => {
            const serviceObj = {
                title: '포도아이',
                en_title: 'PODO I',
                description: '재미있고 효과적인\n체계적인 1:1 맞춤 교육 플랫폼',
                type: 'i',
                index: 3,
            };
            const nextPage = current < next || current === 3;
            const prevPage = current > next || current === 0;
            let serviceFilter:IService;

            if (nextPage) {
                serviceFilter = serviceData.find((item) => item.index === current) || serviceObj;

                setSlideInfo(serviceFilter);
            }

            if (prevPage) {
                serviceFilter = serviceData.find((item) => item.index === next - 1) || serviceObj;

                setSlideInfo(serviceFilter);
            }
        },
    };

    const serviceData:IService[] = [
        {
            title: '포도아이',
            en_title: 'PODO I',
            description: '재미있고 효과적인\n체계적인 1:1 맞춤 교육 플랫폼',
            type: 'i',
            index: 3,
        },
        {
            title: '포도시드',
            en_title: 'PODO SEED',
            description: '블록체인 기반의\n크리에이터 인큐베이팅 플랫폼',
            type: 'seed',
            index: 0,
        },
        {
            title: '포도케어',
            en_title: 'PODO CARE',
            description: '가치 있는 경험을 공유하고\n소통하는 건강 플랫폼',
            type: 'health',
            index: 1,
        },
        {
            title: '포도리뉴',
            en_title: 'PODO RENEW',
            description: '일상 속 다양한 생활 자원의 재활용\n디지털 환경 플랫폼',
            type: 'renew',
            index: 2,
        },
    ];

    return (
        <SectionBackground>
            <Container>
                <TitleBox>
                    <HomeTitle title="Our Services" />

                    <SectionTitle>
                        <Sectionlight>
                            {slideInfo.title}
                            <LinkButton>
                                Coming soon
                            </LinkButton>
                        </Sectionlight>
                        {slideInfo.en_title}
                    </SectionTitle>

                    <SectionSubTitle dangerouslySetInnerHTML={{ __html: nl2br(slideInfo.description) }} />

                </TitleBox>
                <SlideBox>
                    <CustomSlick
                        {...settings}
                        width={window.outerWidth.toString()}
                    >
                        {serviceData.map((item, index) => (
                            <SlideItems
                                key={`podo-${item.type}-${index}`}
                            >
                                <SlideImage src={`/img/home/img_podo_${item.type}.png`} />
                            </SlideItems>
                        ))}
                    </CustomSlick>
                </SlideBox>
            </Container>
        </SectionBackground>
    );
}

const SectionBackground = styled.div`
    background-color: ${colors.BlueGray300};
    padding-top: 71px;
    padding-bottom: 89px;
    width: 100%;
    overflow: hidden;
`;

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
`;

const SectionTitle = styled.div`
    margin-top: 22px;
    font-size: 18px;
    color: ${colors.Black200};
    line-height: 1.4;
`;

const Sectionlight = styled.div`
    font-size: 30px;
    color: ${colors.Blue100};
    font-weight: 700;
    position: relative;
`;

const SectionSubTitle = styled.div`
    margin-top: 25px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    color: ${colors.BlueGray800};
`;

const LinkButton = styled.div`
    width: 103px;
    height: 32px;
    border: 1px solid ${colors.BlueGray700};
    background-color: ${colors.BlueGray400};
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.BlueGray800};
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
`;

const TitleBox = styled.div`
    width: 100%;
    padding: 0 16px;
    margin-bottom: 32px;
`;

const SlideBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CustomSlick = styled(Slider)<{width: string}>`
    width: 658px;

    .slick-dots {
        left: 50%;
        width: ${(props) => (`calc(${props.width}px - 32px)`)};
        bottom: -55px;
        transform: translate(-50%, 0);

        & li {
            margin: 0;
        }

        & li,
        & li button {
            width: 25%;
            height: 6px;
            overflow: hidden;

            &:before {
                display: none;
            }
        }

        & li button { 
            width: 100%;
        }

        & li button {
            background-color: ${colors.BlueGray400};
        }

        & li.slick-active,
        & li.slick-active button {
            width: 25%;
            height: 6px;
            overflow: hidden;

            &:before {
                display: none;
            }
        }

        & li.slick-active button{
            width: 100%;
        }

        & li.slick-active button {
            background-color: ${colors.BlueGray800};
        }
    }

    .slick-track {
        display: flex;
        align-items: center;

    }

    .slick-slide.slick-cloned,
    .slick-slide {
        padding: 0 20px;
    }

    .slick-slide.slick-cloned.slick-center,
    .slick-slide.slick-center.slick-current {
        padding: 0;
    }
`;

const SlideItems = styled.div``;

const SlideImage = styled('img')`
    width: 100%;
`;

export default Services;
