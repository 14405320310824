import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';
// import { colors } from '@utils/ui_palette';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Components
import HomeTitle from '@components/home/component/HomeTitle';

function Partners() {
    useEffect(() => {
        AOS.init();
    });

    return (
        <Container>
            <TitleBox>
                <AnimationBox
                    data-aos="fade-up"
                    data-aos-duration="3000"
                >
                    <HomeTitle title="Parthners" />
                </AnimationBox>

                <PartnerImageBox
                    data-aos="fade-up"
                    data-aos-duration="3000"
                >
                    <PartnerImage src="/img/home/img_podoclub.png" />
                    <PartnerImage src="/img/home/img_podoacademy.png" />
                    <PartnerImage src="/img/home/img_globalpodo.png" />
                </PartnerImageBox>
            </TitleBox>
        </Container>

    );
}

const Container = styled.div`
    width: 100%;
    padding: 0 16px;
    padding-top: 121px;
    padding-bottom: 181px;
    margin: 0 auto;
`;

const AnimationBox = styled.div``;

const TitleBox = styled.div``;

const PartnerImageBox = styled.div`
    margin-top: 23px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const PartnerImage = styled('img')`
    flex-basis: calc(50% - 5px);
    width: calc(50% - 5px);
    flex-shrink: 0;
`;

export default Partners;
